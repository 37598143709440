/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import brandWhite from "assets/images/logo-login.png";
import bgImage from "assets/images/bg-sign-in-basic.jpg";

// Authentication layout components
import { Get } from "../../tools/Helper";

function DescargarFicha() {

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ status: null, message: "" });

    const { id } = useParams(); // Unpacking and retrieve id

    const getData = async e => {

        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = await Get(`descargarFicha/${id}`);

        console.log('descargarFicha: ');
        console.log(request);

        if ((request.status === "success" ||
            request.status === "empty") && 
            request.data
        ) {
            
            const a = document.createElement('a');
            const format = request.data.ficha.split(';')[0].split('/')[1];
             a.download = `${request.data.clave}.${format}`;
            a.href = request.data.ficha;
            a.click();                             

        } else {
            setStatus({ status: request.status, message: "Error." });
        }

        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <BasicLayout image={bgImage}>
      
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            position="relative"
          >
            <MDBox component="img" src={brandWhite} alt="Brand" textAlign="center" position="absolute" top="-9.5rem" right={0} left={0} mx="auto" width="12rem"/>
                <MDTypography variant="h5" fontWeight="medium" color="white">
                La ficha técnica se descargará en unos segundos...
                </MDTypography>
            </MDBox>          
        </Card>
      </BasicLayout>
    );
}

export default DescargarFicha;
