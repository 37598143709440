/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import Icon from "@mui/material/Icon";
import { useParams, Link } from 'react-router-dom';

import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import Modal from 'react-modal';
import { Get, Del, Post } from "../../tools/Helper";
import FichasForm from "./FichasForm";
import PDFGenerator from "../../tools/PDFGenerator";

// TODO: 
// _ Paginacion
// _ Validar si el elemento a eliminar ya se está usando

function Fichas() {

    const { id } = useParams(); // Unpacking and retrieve id

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            maxWidth: '600px',
        },
    };

    const [show, setShow] = useState(true);
    const [editar, setEditar] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenDel, setIsOpenDel] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ status: null, message: "" });
    const [selectedDelete, setSelectedDelete] = useState("");
    const [servicio, setServicio] = useState({});
    const [formData, setFormData] = useState({id_servicio: null, clave: '', ficha: null, descripcion: ''});
    const [filePickerId, setFilePickerId] = useState("");
    const [selectedSheet, setSelectedSheet] = useState("");

  const inputRef = useRef(null);

    const showSheetT = async (row) => {

        if (row.url_google_drive) {

            window.open(row.url_google_drive);

        } else {
                
            setStatus({ status: null, message: "" });
            setLoading(true);
            const request = await Get(`descargarFicha/${row.id_ficha}`);

            if (request.status === "success" ||
                request.status === "empty"
            ) {
                const pdfWindow = window.open("");
                pdfWindow.document.write(`<body style='margin:0;'><iframe width='100%' frameBorder='0' style='margin: 0; padding: 0;' height='100%' src='${encodeURI(request.data.ficha)}'></iframe></body>`);
                setStatus({ status: request.status, message: request.message });
                setLoading(false);
            } else {
                setStatus({ status: request.status, message: request.message });
                setLoading(false);
            }
        }
    }    

    const showSheetTsd = async (row) => {
        // setIsOpen(true);
        // setSelectedSheet(row.ficha);
        const pdfWindow = window.open("");
        pdfWindow.document.write(`<body style='margin:0;'><iframe width='100%' frameBorder='0' style='margin: 0; padding: 0;' height='100%' src='${encodeURI(row.ficha)}'></iframe></body>`);
    }

    const editarButton = (row) => {
        setSelectedData({...row, servicio});
        setShow(false);
        setEditar(true);
    }

    const getData = async e => {

        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = await Get(`fichas-servicio/${id}`);

        if (request.status === "success" ||
            request.status === "empty"
        ) {
            setData(request.data);
            setServicio(request.servicio);
            setStatus({ status: request.status, message: "Información cargada exitosamente." });
        } else {
            setStatus({ status: request.status, message: "Error." });
        }

        setSelectedData({});
        setLoading(false);
    }

    const modifySheet = (sheet, name) =>{
        setFormData({...formData, ficha:sheet, fichaName: name});
        if(sheet === null){
          const randomString = Math.random().toString(10);
          setFilePickerId(randomString);
        }
    }

    const handleClick = () => {
        // open file input box on click of other element
        inputRef.current.click();
    };

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
            modifySheet(reader.result, event.target.files[0].name);
        };
    }

    useEffect(() => {
        setFormData({...formData, id_servicio: id, clave: 'FT'});
        getData();
    }, []);

    const handleChange = (e, name = "", value = "") =>{
        if(name !== ""){
          setFormData({...formData, tipo:e[name], tipoArr: e});
        }else{
          setFormData({...formData, [e.target.name]:e.target.value});
        }
    }

    const doRequest = async e =>{
        setStatus({status: null, message: ""});
        setLoading(true);
        const tempData = formData;
        const request = await Post('fichas',formData) ;

        setLoading(false);
        
        if(request.status === "success"){
            setFormData({...formData, descripcion: '', ficha: null});
            await Post('googleDriveFileUpload', {...formData, id_ficha: request.data.id_ficha});
            await getData();
        if(editar){
            setFormData(tempData);
        }
        } else {
            setLoading(false);
        }

        setStatus(request);
        setLoading(false);
    }

    const verifyError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return true;
          }
        }
    
        return false;
    }
    
      const getError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return status.errors[name][0];
          }
        }
    
        return "";
    }

    const closeModalF = () => {
        setIsOpen(false);
        setSelectedDelete(null);
    }

    function openModalDel(idF) {
        setIsOpenDel(true);
        setSelectedDelete(idF);
    }

    const closeModalDel = () => {
        setIsOpenDel(false);
        setSelectedDelete(null);
    }

    const deleteFicha = async () => {        
        
        closeModalDel();
        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = await Del(`fichas/${selectedDelete}`);

        if (request.status === "success") {
            await getData();
        }

        setStatus(request);
        setShow(true);
        setLoading(false);
    }

    const downloadSheet = (selectedSh) => {
        const a = document.createElement('a');
        const format = selectedSh.split(';')[0].split('/')[1];
        a.download = `sheet.${format}`;
        a.href = selectedSh;
        a.click();
    }

    const showSheet = (row) => <Tooltip title="Ver ficha"><MDButton color="info" iconOnly onClick={() => showSheetT(row)}><Icon>search</Icon></MDButton></Tooltip>;    
    const editButton = (row) => <Tooltip title="Editar"><MDButton  className="btnPaymun btnChangeStatus" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
    const deleteButton = (row) => <Tooltip title="Eliminar"><MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModalDel(row.id_ficha)}><Icon>deleteForever</Icon></MDButton></Tooltip>;

    const columns = [
        {
            name: 'Clave',
            selector: row => row.clave,
            width: "90px",
            grow: 1,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
            wrap: true,
            grow: 3,
        },
        {
            name: 'Fecha',
            selector: row => new Date(row.created_at).toLocaleDateString('MX'),
            wrap: true,
            grow: 3,
        },
        {
            name: '',
            selector: row => showSheet(row),
            grow: 0,
            compact: true,
            width: "60px",
        },
        {
            name: '',
            selector: row => deleteButton(row),
            grow: 0,
            compact: true,
            width: "60px",
        },
    ];

    const tableData = {
        columns,
        data,
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>

                <Fade in={show} timeout={800}>
                    <Collapse in={show} timeout={50}>
                        <Card>
                            <MDBox 
                                p={3}
                                className="headerFormPymn"
                            >
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                            Fichas Técnicas
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                <MDBox px={3}>
                                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                                        <CircularProgress size="10rem" />
                                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                                    </MDBox>
                                        :
                                        <MDBox>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    CLAVE:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(servicio !== undefined) ? servicio?.clave : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    UNIDAD:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(servicio !== undefined) ? servicio?.nombre_unidad : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    DEPARTAMENTO:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(servicio !== undefined) ? servicio?.nombre_dept : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    DESCRIPCIÓN:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(servicio !== undefined) ? servicio?.descripcion : ''}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            <Divider className="fullWidth" />
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={2} mt="auto">
                                                    {
                                                    formData.ficha ?
                                                    <MDButton variant="gradient" color="error"
                                                        display="inline"
                                                        onClick={()=>modifySheet(null)} fullWidth>
                                                        Eliminar Ficha
                                                    </MDButton>
                                                    :
                                                    <MDButton variant="gradient" color="info"
                                                        display="inline"
                                                        onClick={()=>handleClick()} fullWidth>
                                                        Añadir Ficha
                                                    </MDButton>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} lg={2} mt="auto">
                                                    {/* <MDTypography variant="h6" display="inline" pr={1}>
                                                        Archivo:
                                                    </MDTypography> */}
                                                    <input
                                                        type="file"
                                                        name="myImage"
                                                        style={{display:'none'}}
                                                        key={filePickerId || ''}
                                                        ref={inputRef}
                                                        onChange={handleFileChange}
                                                    />
                                                    {formData.ficha ?
                                                        <fragment>
                                                            <MDTypography variant="h6" display="inline" fontWeight="light">
                                                            {formData.fichaName}
                                                            </MDTypography>
                                                            {/* <img alt="not found" style={{borderRadius: 10}} src={formData.ficha} className="w-100" width="50%" /> */}
                                                        </fragment>
                                                        : <fragment>
                                                            <MDTypography variant="h6" display="inline" fontWeight="light">
                                                            Ningún archivo seleccionado
                                                            </MDTypography>
                                                        </fragment>
                                                        }
                                                </Grid>
                                                <Grid item xs={12} lg={8} mt="auto">
                                                    <MDInput type="text" label="Descripción" multiline rows={1} name="descripcion"
                                                        error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                                                        value={formData.descripcion} onChange={event => handleChange(event)} fullWidth inputProps = {{ maxLength: 200 }} />
                                                </Grid>
                                                <Grid item md={4} sm={12}>
                                                &nbsp;
                                                </Grid>
                                                <Grid item md={2} sm={12} mt={2}>
                                                    <MDButton disabled={loading}
                                                        className="btnPaymun"
                                                        onClick={() => doRequest()} fullWidth >
                                                        <Icon fontSize="small">done</Icon>
                                                        &nbsp;Agregar
                                                        
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                            <DataTableExtensions
                                            {...tableData}
                                            export={false}
                                            print={false}
                                            filterPlaceholder="Buscar..."
                                            >
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                pagination
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Fichas por página:',
                                                    rangeSeparatorText: 'de',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: true,
                                                    selectAllRowsItemText: 'Todos'
                                                }}
                                                noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                                            />
                                            </DataTableExtensions>
                                            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                                                <Grid item md={2} sm={12} mb={4}>
                                                    <Link to="/catalogos/servicios">
                                                        <MDButton
                                                            onClick={() => setShow(true)} 
                                                            className="btnBackPaymun" fullWidth>
                                                            <Icon fontSize="small">arrow_back</Icon>
                                                            &nbsp;Regresar
                                                        </MDButton>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    }
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Collapse>
                </Fade>

                <Fade in={!show} timeout={800}>
                    <Collapse in={!show} timeout={50}>
                        <FichasForm data={selectedData} 
                                    editar={editar} 
                                    setShow={setShow}
                                    getLista={getData} />
                    </Collapse>
                </Fade>

            </MDBox>            

            <Modal style={{ overlay: { backgroundColor: "#fff" } }}
                isOpen={modalIsOpen}
                onRequestClose={closeModalF}
                contentLabel="Ficha técnica"
                center
                >
                <Grid container spacing={6} pb={2}>
                    <Grid item xs={4} />
                    <Grid item xs={4} textAlign="center" >
                        <MDButton variant="gradient" color="info" style={{marginRight: 10}} onClick={()=>downloadSheet(selectedSheet)}>
                            Descargar
                        </MDButton>
                        <MDButton variant="gradient" color="error" onClick={()=>closeModalF()}>
                            Cerrar
                        </MDButton>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
                <div style={{textAlign: 'center'}}>
                    <img alt="ficha" width="50%" style={{borderRadius: 10}} src={selectedSheet}/>
                </div>
                </Modal>
                <Modal
                isOpen={modalIsOpenDel}
                contentLabel="¿Seguro que desea eliminar el registro?"
                style={customStyles}
                >
                
                <Grid container spacing={2} p={4}>
                    <h2>¿Seguro que desea eliminar la ficha técnica?</h2>
                    

                    <Grid item md={6} sm={12} xs={12} className='alignRigth' >
                        <MDButton className="btnPaymun" disabled={loading}
                            onClick={() => deleteFicha()} >
                            <Icon fontSize="small">done</Icon>
                            &nbsp;Eliminar
                        </MDButton>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} className="alignLeft">
                        <MDButton className="btnBackPaymun" disabled={loading}
                            onClick={() => closeModalDel()} >
                            <Icon fontSize="small">arrow_back</Icon>
                            &nbsp;Cancelar
                        </MDButton>
                    </Grid>

                    {/* 
                    <Grid item xs={6}>
                    <MDButton variant="gradient" color="info" disabled={loading}
                        onClick={()=>closeModalDel()} fullWidth>
                        Cancelar
                    </MDButton>
                    </Grid>
                    <Grid item xs={6}>
                    <MDButton variant="gradient" color="error" disabled={loading}
                        onClick={()=>deleteFicha()} fullWidth>
                        Eliminar
                    </MDButton>
                    </Grid> */}
                </Grid>
                </Modal>
            <Footer />
            
        </DashboardLayout>
    );
}

// Setting default props for the ProfileInfoCard
Fichas.defaultProps = {
    shadow: true,
};
  
  // Typechecking props for the ProfileInfoCard
  Fichas.propTypes = {    
    action: PropTypes.shape({
      route: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
    }).isRequired,
    shadow: PropTypes.bool,
  };

export default Fichas;