/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { interpolate } from "chroma-js";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Select from 'react-select';

import Icon from "@mui/material/Icon";
import PropTypes, { number } from 'prop-types';
// import CurrencyInput fro../../tools/CurrencyInput.bckput'
import { NumericFormat } from 'react-number-format';

import moment from 'moment';
import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";
import { onlyNumbers } from '../../tools/utils';

function InventarioForm({ editar, data, setShow, getLista, setShowDetail, getMovimientos }) {

    const [inputKey, setInputKey] = useState();
    const [formData, setFormData] = useState(data);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ status: null, message: "" });
    const [insumos, setInsumos] = useState([]);    
    const [proveedores, setProveedores] = useState([]);

    const getInsumos = async e => {
        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = await Get('listaInsumos');

        if (request.status === "success") {
            setInsumos(request.data);
        } else {
            setStatus(request);
        }

        setLoading(false);
    }

    const getProveedores = async (insumo) => {
        setProveedores([]);

        if (insumo.id_insumo === undefined || insumo === null)
            return;

        setFormData({
            ...formData,
            'insumos': insumo,
            'proveedores': '',
        });

        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = await Get(`getProveedorByInsumo/${insumo.id_insumo}`);

        if (request.status === "success") {
            setProveedores(request.data);
        } else {
            setStatus(request);
        }

        setLoading(false);
    }

    useEffect(() => {

        getInsumos();

        if (editar) {
            getProveedores(data.insumos);
        }                

        setFormData(data);
        setStatus({ status: null, message: "" });

    }, [data, setFormData]);

    const handleChange = (e, name = "", value = "") => {

        if (e.target !== undefined &&
            e.target.name === 'cantidad') {
            if (e.target.value.split("").length > 4) {
                e.target.value = e.target.value.slice(0, 4)
            }
            if (!/[0-9]/.test(e.key) && e.key !== "Delete" && e.key !== "Backspace") {
                e.preventDefault();
            }

            e.target.value = e.target.value.replace(/^0+/, '');
        }

        if (name === "") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        } else {
            setFormData({ ...formData, [value]: e[value], [name]: e });
        }

        if ("errors" in status) {
            const verifyName = name === "" ? e.target.name : name;
            if (verifyName in status.errors) {
                const temp = status;
                delete temp.errors[verifyName];
                setStatus(temp);
            }
        }

        if (name === "insumo") {
            getProveedores(e);
        }

    }

    const doRequest = async e => {

        setStatus({ status: null, message: "" });
        setLoading(true);

        const request = editar ? await Put(`inventario/${formData.id_inventario}`, formData) : await Post('inventario', formData);

        if (request.status === "success") {

            if (editar === true) {
                getMovimientos(formData.id_insumo);
                setShowDetail(false);
                setShow(true);
            }
            else {
                await getLista();
                setShow(true);
            }

        }
        else if (request.status === 'error') {
            setStatus(request);
        }

        setLoading(false);

    }

    const verifyError = (name) => {
        if ("errors" in status) {
            if (name in status.errors) {
                return true;
            }
        }

        return false;
    }

    const getError = (name) => {
        if ("errors" in status) {
            if (name in status.errors) {
                return status.errors[name][0];
            }
        }

        return "";
    }

    const backToParent = () => {

        if (editar === true) {
            getMovimientos();
            setShowDetail(false);
            setShow(true);
        }
        else {
            setShow(true);
        }

    }

    return (
        <MDBox pb={3}>
            <Card mt={4}>
                <MDBox
                    p={3}
                    className="headerFormPymn"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                {editar ? "Actualizar" : "Agregar"} Insumo a Inventario
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>

                <MDBox p={3} >
                    <Message status={status} />
                    <Grid container spacing={2}>
                        {loading ?
                            <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                                <CircularProgress size="6.66rem" />
                                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                            </Grid>
                            : <>
                                <Grid item md={2} xs={12} >
                                    <MDInput type="date" label="* Fecha" name="fecha"
                                        error={verifyError("fecha")} errorMsg={getError("fecha")}
                                        value={moment(formData.fecha).locale('es').format('YYYY-MM-DD')} onChange={handleChange} fullWidth />
                                </Grid>

                                <Grid item md={5} xs={12} mt="auto">
                                    <Select className={`z-10 ${verifyError("insumo") ? "border-red" : ""}`}
                                        placeholder="* Insumo" value={formData.insumos}
                                        onChange={(e) => handleChange(e, "insumo", "insumo")}
                                        options={insumos} isDisabled={editar}
                                        getOptionValue={(option) => `${option.id_insumo}`}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                    />
                                    <small className="font-red">{getError("insumo")}</small>
                                </Grid>

                                <Grid item md={5} xs={12}>
                                    <Select className={`z-10 ${verifyError("proveedor") ? "border-red" : ""}`}
                                        placeholder="* Proveedor" value={formData.proveedores}
                                        onChange={(e) => handleChange(e, "proveedores", "proveedores")}
                                        options={proveedores}
                                        getOptionValue={(option) => `${option.id_proveedor}`}
                                        getOptionLabel={(option) => `${option.empresa}`}
                                    />
                                    <small className="font-red">{getError("proveedores")}</small>
                                </Grid>

                                <Grid item md={4} xs={12} mt="auto">
                                    <MDInput type="text" label="* Clave" name="clave"
                                        error={verifyError("clave")} errorMsg={getError("clave")}
                                        inputProps={{ disabled: true, }}
                                        value={formData.insumos ? formData.insumos.clave : ''} fullWidth />
                                </Grid>

                                <Grid item md={4} xs={12} mt="auto">
                                    <MDInput type="number" label="* Cantidad" name="cantidad"
                                        error={verifyError("cantidad")} errorMsg={getError("cantidad")}
                                        inputProps={{ max: "9999", min: "1" }}
                                        onKeyPress={onlyNumbers}
                                        value={formData.cantidad} onChange={event => handleChange(event)} fullWidth />
                                </Grid>

                                <Grid item md={4} xs={12} mt="auto">
                                    { /*
                                    <CurrencyInput placeholder="$0.00" type="text" />

                                */ }
                                    {/* <MDInput type="number" label="* Precio" name="precio"
                                        error={verifyError("precio")} errorMsg={getError("precio")}
                                        inputProps={{ max: "9999", min: "1" }}
                                        value={formData.precio} onChange={event => handleChange(event)} fullWidth /> */}
                                    <NumericFormat
                                        value={formData.precio}
                                        onValueChange={(values) => {
                                        const { formattedValue, floatValue, value } = values;
                                        setFormData({
                                            ...formData, 
                                            'precio': value
                                        });
                                        }}
                                        prefix='$'
                                        customInput={MDInput}
                                        thousandSeparator=','
                                        decimalScale={2}
                                        fixedDecimalScale
                                        label="* Precio"
                                        name="precio"
                                        error={verifyError("precio")}
                                        helperText={getError("precio")}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item md={4} xs={12}>
                                    &nbsp;
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <MDButton disabled={loading}
                                        className="btnPaymun"
                                        onClick={() => doRequest()} fullWidth 
                                        style={{ padding:"10px 4px", borderRadius: "0.5rem" }} >
                                        <Icon fontSize="small">done</Icon>
                                        &nbsp;{editar ? "Actualizar" : "Agregar"}

                                    </MDButton>
                                </Grid>

                                <Grid item md={2} xs={12}>
                                    <MDButton
                                        onClick={() => backToParent()}
                                        className="btnBackPaymun" fullWidth
                                        style={{ padding:"10px 4px", borderRadius: "0.5rem" }} >
                                        <Icon fontSize="small"                                         
                                         >arrow_back</Icon>
                                        &nbsp;Regresar
                                    </MDButton>
                                </Grid>
                            </>
                        }
                    </Grid>
                </MDBox>

            </Card>

        </MDBox>
    );
}

InventarioForm.propTypes = {
    editar: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    getLista: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    setShowDetail: PropTypes.func.isRequired,
    getMovimientos: PropTypes.func.isRequired,
};

export default InventarioForm;