import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import { Divider } from '@mui/material';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';
import { parseCurrency } from './utils';

function PDFGeneratorCot({data}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 10,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    firma: {
      width: 150,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    table:{
      width:"92%",
      height:"92%",
      display:"flex",
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },

    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },

    flexY:{
      flex: "1 1 auto",
    },

    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },

    flex5:{
      flex:5,
    },

    borderX:{
      borderRight:1,
      borderLeft:1,
    },

    borderY:{
      borderTop:1,
      borderBottom:1,
    },

    borderS:{
      borderLeft:1,
    },

    borderE:{
      borderRight:1,
    },

    borderT:{
      borderTop:1,
    },

    borderB:{
      borderBottom:1,
    },

    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 4,
      paddingBottom: 4,
    },

    textCenter:{
      textAlign:"center",
    },

    py10:{
      paddingTop:30,
      paddingBottom:30,
    },

    flex3:{
      flex:3,
    },

    flex2:{
      flex:2,
    },

    flex1:{
        flex:1,
    },

    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },

    img:{
      display:"block",
      width:"60%",
    },

    img2:{
      display:"block",
      position:"absolute",
      width:"20%",
      right:28,
      top: 22,
    },

    red:{
      color:"#ff0004"
    },

    textSmall:{
      fontSize: 8,
    },

    textSmallx2:{
      fontSize: 7,
      padding: 2
    },

    textSmallx3:{
      fontSize: 5,
    },

    textSmallBold:{
      fontSize: 8,
      fontWeight:"heavy",
    },

    textLarge:{
      fontSize: 10,
    },

    textLargeRed:{
      fontSize: 10,
      color: 'red',
      fontWeight:"heavy",
    },

    alend:{
      textAlign:"right"
    },

    pagina:{
      position:"fixed",
      bottom:15,
      left:0,
      paddingRight:28,
      textAlign:"right",
      fontSize:7,
    },

    receptor:{
        height: 60
    },

    textWrapp: {
      textWrap: 'wrap',
      marginTop: 4,
      marginBottom: 5,
      padding: 5
    },

    marginTopMedium: {
      marginTop: 5
    },
  });

  const Conceptos = (serv) => serv.map( concepto => 
    <View style={[styles.row, styles.borderB]} key={`concepto-${concepto.id_pivot}`}>
      <View style={[styles.col, styles.borderE, styles.flex1]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>{concepto.clave}</Text>
      </View>
      <View style={[styles.col, styles.borderE, styles.flex1,]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>{concepto.cantidad}</Text>
      </View>
      <View style={[styles.col, styles.borderE, styles.flex2]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>{concepto.unidad}</Text>
      </View>
      <View style={[styles.col, styles.flex5, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmallx2, styles.textWrapp]}>{concepto.descripcion}</Text>
      </View>
      <View style={[styles.col, styles.borderE, styles.flex2]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>{parseCurrency(parseFloat(concepto.importe))}</Text>
      </View>
      <View style={[styles.col, styles.flex2]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>
          {parseCurrency( (parseFloat(concepto.importe) * parseInt(concepto.cantidad, 10)).toFixed(2))}
        </Text>
      </View>
    </View>);

  const size = 10; const arrayOfArrays = [];
  for (let i=0; i<data.servicios.length; i+=size) {
    arrayOfArrays.push(data.servicios.slice(i,i+size));
  }

  const Paginas = () => arrayOfArrays ? arrayOfArrays.map((servicios,i) => 
    <Page size="A4" wrap key={`page-${servicios.id_pivot}`}>
      
      <View style={styles.table}>
        <View style={[styles.row]}>
            <View style={[styles.col, {flex: 1}]}>
                <Image src={Logo} style={styles.img}/>
            </View>
            <View style={[styles.col, {flex: 1}]}>
                <Text style={[styles.center, styles.textSmall]}>Paymun</Text>
                <Text style={[styles.center, styles.textSmall]}>PAY210628BD3</Text>
                <Text style={[styles.center, styles.textSmall]}>CALLE AURORA S/N ENTRE CALLE SAN FRANCISCO Y CALLE RAYÓN SN COL: SAN ISIDRO C.P.: 86357, COMALCALCO, TABASCO, MÉXICO</Text>
                <Text style={[styles.center, styles.textSmall]}>Tel: 9331810337 Cel: 9331631158</Text>
                <Text style={[styles.center, styles.textSmall]}>contacto@paymun.mx</Text>
            </View>
            <View style={[styles.col, {flex: 1}]}>
                
              <View style={[styles.col, styles.head]}>
                    <Text style={[styles.center,styles.alend]}>Cotización</Text>
                </View>
                <View style={[styles.col]}>
                    <Text style={[styles.center,styles.alend]}>{data.num_cotizacion}</Text>
                </View>

                <View style={[styles.col, styles.head]}>
                    <Text style={[styles.center,styles.alend]}>Fecha</Text>
                </View>
                <View style={[styles.col]}>
                    <Text style={[styles.center,styles.alend]}>{data.fecha_cotizacion}</Text>
                </View>
                <View style={[styles.col, styles.head]}>
                    <Text style={[styles.center,styles.alend]}>Vigencia: {data.vigencia} días</Text>
                </View>
                
            </View>
        </View>
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row, styles.borderT]}>
          <View style={[styles.col, {flex: 1.5}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Nombre Comercial:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.nombre_comercial}</Text>
          </View>
          <View style={[styles.col,{flex: 0.8}]}>
            <Text style={[styles.center, styles.textSmallx2]}>RFC:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.rfc}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={[styles.col,{flex: 1.5}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Razón Social:</Text>
          </View>
          <View style={[styles.col, styles.flex3, styles.textSmallBold]}>
            <Text style={[styles.center]}>{data.cliente.razon_social}</Text>
          </View>
          <View style={[styles.col,{flex: 0.8}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Tel:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.telefono}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={[styles.col,{flex: 1.5}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Persona Contacto:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.persona_contacto}</Text>
          </View>
          <View style={[styles.col,{flex: 0.8}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Cel:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]} />
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col,{flex: 1.5}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Dirección:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.calle_num_fiscal}, {data.cliente.colonia_fiscal}, {data.cliente.municipio_fiscal}, {data.cliente.estado_fiscal}.</Text>
          </View>
          <View style={[styles.col,{flex: 0.8}]}>
            <Text style={[styles.center, styles.textSmallx2]}>Email:</Text>
          </View>
          <View style={[styles.col, styles.flex3]}>
            <Text style={[styles.center, styles.textSmallBold]}>{data.cliente.correo}</Text>
          </View>
        </View>
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textSmallx2]}>Sirvase recibir amablemente la presente propuesta económica de servicios/productos que nuestra empresa Paymun pone a su consideración. A continuación se detallan los precios e impuestos:</Text>
          </View>
        </View>
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.head, styles.flex1]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>CLAVE</Text>
          </View>
          <View style={[styles.col, styles.head, styles.flex1]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>CANT.</Text>
          </View>
          <View style={[styles.col, styles.head, styles.flex2]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>UNIDAD</Text>
          </View>
          <View style={[styles.col, styles.flex5, styles.head]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>DESCRIPCIÓN</Text>
          </View>
          <View style={[styles.col, styles.head, styles.flex2]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>P. UNIT</Text>
          </View>
          <View style={[styles.col, styles.head, styles.flex2]}>
            <Text style={[styles.center,styles.textCenter, styles.textSmallx2]}>IMPORTE</Text>
          </View>
        </View>
        {Conceptos(servicios)}
        <Divider style={[styles.marginTopMedium]} />

      {
        arrayOfArrays.length === (i+1) ?
        <>
          <View style={[styles.row]}>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter, styles.py10]} />
            </View>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter, styles.py10]} />
            </View>
            <View style={styles.col}>
              <View style={[styles.row]}>
                <View style={[styles.col, styles.flex1, styles.head]}>
                  <Text style={[styles.center, styles.textSmallx2]}>Subtotal:</Text>
                </View>
                <View style={[styles.col, styles.flex2]}>
                  <Text style={[styles.center, styles.alend]}>{parseCurrency(data.subtotal)}</Text>
                </View>
              </View>
              {
                parseFloat(data.descuento) > 0 ?
                <>
                  <View style={[styles.row, styles.borderT]}>
                    <View style={[styles.col, styles.flex1, styles.head]}>
                      <Text style={[styles.center, styles.textSmallx2]}>Desc:</Text>
                    </View>
                    <View style={[styles.col, styles.flex2]}>
                      <Text style={[styles.center, styles.alend]}>{data.descuento}%</Text>
                    </View>
                  </View>
                  <View style={[styles.row, styles.borderT]}>
                    <View style={[styles.col, styles.flex1, styles.head]}>
                      <Text style={[styles.center, styles.textSmallx2]}>Subtotal Desc:</Text>
                    </View>
                    <View style={[styles.col, styles.flex2]}>
                      <Text style={[styles.center, styles.alend]}>{parseCurrency(data.montoDescuento)}</Text>
                    </View>
                </View>
                </>
                : null
              }
              <View style={[styles.row, styles.borderT]}>
                <View style={[styles.col, styles.flex1]}>
                  <Text style={[styles.center, styles.textSmallx2]}>IVA:</Text>
                </View>
                <View style={[styles.col, styles.flex2]}>
                  <Text style={[styles.center, styles.alend]}>{parseCurrency(data.iva)}</Text>
                </View>
              </View>
              <View style={[styles.row, styles.borderT]}>
                <View style={[styles.col, styles.flex1, styles.head]}>
                  <Text style={[styles.center, styles.textSmallx2]}>Total:</Text>
                </View>
                <View style={[styles.col, styles.flex2]}>
                  <Text style={[styles.center, styles.alend]}>{parseCurrency(data.total_descuento)}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter]}/>
            </View>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}/>
            </View>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}/>
            </View>
          </View>
          
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Comentarios adicionales</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.comentarios_0 ? data.comentarios_0?.descripcion : data.comentarios0?.descripcion}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.comentarios_1 ? data.comentarios_1?.descripcion : data.comentarios1.descripcion}</Text> 
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.comentarios_2 ? data.comentarios_2?.descripcion : data.comentarios2.descripcion}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data?.info_adicional}</Text>
          </View>
        </View>
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Condiciones comerciales</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.condiciones_0 ? data.condiciones_0?.descripcion : data.condiciones0.descripcion}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.condiciones_1 ? data.condiciones_1?.descripcion : data.condiciones1.descripcion }</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data.condiciones_2 ? data.condiciones_2?.descripcion : data.condiciones2.descripcion}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col]}>
            <Text style={[styles.center, styles.textSmallx2,  styles.textWrapp]}>{data?.otra_condicion}</Text>
          </View>
        </View>
        <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row]}>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter]} />
            </View>
            <View style={styles.col}>
              {/* <View style={{ justifyContent: 'center', alignItems: 'center'}}>
                <Image style={styles.firma} src={data.firma} alt="firma" />
              </View> */}
              <View style={[styles.row]}>
                <View style={[styles.col, styles.borderB, styles.flex1, {justifyContent: 'center', alignItems: 'center'}]}>
                    <Image src={data.firma} style={styles.firma}/>
                </View>
              </View>
            </View>
            <View style={[styles.col]}>
              <Text style={[styles.center,styles.textCenter, styles.py10]} />
            </View>
        </View>
        <View style={[styles.row]}>
            {/* <View style={[styles.col, styles.flex2]}>
              <Text style={[styles.center, styles.textLargeRed]}>Todos los precios son más I.V.A.</Text>
            </View> */}
            <View style={[styles.col, styles.flex1, {justifyContent: 'center', alignItems: 'center'}]}>
              <View style={[styles.col, styles.flex1, styles.textCenter, {justifyContent: 'center', alignItems: 'center'}]}>                <Text style={[styles.textCenter, {marginTop: 10}]}>{data.nombre_vendedor}</Text>
                <Text style={[styles.textCenter, {marginTop: 10}]}>Vendedor</Text>
              </View>
            </View>
          </View>
          </>
        : null
      }
        {/* <Divider style={[styles.marginTopMedium]} />
        <View style={[styles.row]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textSmallx2]}>La autorización de la presente propuesta económica representa un contrato comercial entre el cliente y Paymun</Text>
          </View>
        </View> */}
      </View>
      <Text style={[styles.pagina]}>Pagina {i+1} de {arrayOfArrays.length}</Text>
    </Page>) : "";

	return (
		<Document>
      <Paginas />
    </Document>
	);
}

PDFGeneratorCot.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
};

export default PDFGeneratorCot;