/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { interpolate } from "chroma-js";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Icon from "@mui/material/Icon";
import PropTypes, { number } from 'prop-types';

import moment from 'moment';
import { onlyNumbers } from '../../tools/utils';

import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";

function DistribucionInsumoDeptoForm({ editar, data, setShow, getLista, insumosArr, departamentosArr }) {

    const [inputKey, setInputKey] = useState();
    const animatedComponents = makeAnimated();
    const [formData, setFormData] = useState(data);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ status: null, message: "" });
    const [insumos, setInsumos] = useState([]);
    const [insumo, setInsumo] = useState('');
    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState('');
    const [unidadMedida, setUnidadMedida] = useState('');

    const getClaveAsignacion = async e => {

        if (!editar) {
            setStatus({ status: null, message: "" });
            setLoading(true);

            const request = await Get('getClaveDistribucionInsumo');

            if (request.status === "success") {
                setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });
            } else {
                setStatus(request);
            }

            setLoading(false);
        }

    }

    useEffect(() => {

        setFormData(data);

        setInsumo('');
        setDepartamento('');
        setUnidadMedida('');

        setInsumos(insumosArr);
        setDepartamentos(departamentosArr);

        if (editar) {
            const idInsumo = Number(data.id_insumo);
            const itemInsumo = insumosArr.find(option => option.id_insumo === idInsumo);
            
            setInsumo(itemInsumo);
            setUnidadMedida((itemInsumo) ? itemInsumo.unidad_medida: '');

            const idDepto = Number(data.id_departamento);
            setDepartamento(departamentosArr.find(option => option.id_departamento === idDepto));            
        }

        getClaveAsignacion();
        setStatus({ status: null, message: "" });

    }, [data, setFormData]);

    const doRequest = async e => {

        setStatus({ status: null, message: "" });
        setLoading(true);

        const requestExistencia = await Get(`getExistenciaInsumo/${formData.id_insumo}`);
        
        if (requestExistencia.status === "success") {  
            
            if (formData.cantidad > requestExistencia.data) {
                setStatus({ status: 'error', message: `En el inventario solo existen ${requestExistencia.data} insumos de existencia, corrija la cantidad a asignar.` });
            } else {
                const request = editar ? await Put(`asignacion-insumo-depto/${formData.id_asign_insumo_depto}`, formData) : await Post('asignacion-insumo-depto', formData);

                if (request.status === "success") {
                    await getLista();
                    setShow(true);
                }
                else if (request.status === 'error') {
                    setStatus(request);
                }
            }
             
        } else {                      
          setStatus({ status: 'error', message: "No se logró obtener la existencia del Insumo" });
        }
            
        setLoading(false);
    }

    const verifyError = (name) => {
        if ("errors" in status) {
            if (name in status.errors) {
                return true;
            }
        }

        return false;
    }

    const getError = (name) => {
        if ("errors" in status) {
            if (name in status.errors) {
                return status.errors[name][0];
            }
        }

        return "";
    }

    const handleChange = (e, name = "", value = "") => {

        if (e.target !== undefined &&
            e.target.name === 'cantidad') {
            if (e.target.value.split("").length > 3) {
                e.target.value = e.target.value.slice(0, 3)
            }
        }

        if (name === "") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        } else {
            setFormData({
                ...formData,
                [value]: e[name]
            });
        }

        if (name === 'id_insumo') {
            const itemInsumo = insumos.find(option => option.id_insumo === e[name]);

            setInsumo(itemInsumo);
            setUnidadMedida((itemInsumo) ? itemInsumo.unidad_medida : '');
        }

        if (name === 'id_departamento') {
            setDepartamento(departamentos.find(option => option.id_departamento === e[name]));
        }

        if ("errors" in status) {
            const verifyName = name === "" ? e.target.name : name;
            if (verifyName in status.errors) {
                const temp = status;
                delete temp.errors[verifyName];
                setStatus(temp);
            }
        }
    }

    return (
        <MDBox pb={3}>
            <Card mt={4}>
                <MDBox
                    p={3}
                    className="headerFormPymn"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                {editar ? "Actualizar" : "Agregar"} Distribución de Insumo
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>

                <MDBox p={3} >
                    <Message status={status} />
                    <Grid container spacing={2}>
                        {loading ?
                            <Grid item md={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                                <CircularProgress size="6.66rem" />
                                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                            </Grid>
                            : <>
                                <Grid item md={4} xs={12}>
                                    <MDInput type="text" label="* Clave" name="clave"
                                        error={verifyError("clave")} errorMsg={getError("clave")}
                                        value={formData.clave} onChange={event => handleChange(event)}
                                        inputProps={{ maxLength: 5, readOnly: true, }}
                                        fullWidth />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <MDInput type="date" label="* Fecha" name="fecha"
                                        error={verifyError("fecha")} errorMsg={getError("fecha")}
                                        value={moment(formData.fecha).locale('es').format('YYYY-MM-DD')} onChange={handleChange} fullWidth />
                                </Grid>

                                <Grid item md={4} mt="auto" xs={12}>
                                    <MDInput type="number" label="* Cantidad" name="cantidad"
                                        error={verifyError("cantidad")} errorMsg={getError("cantidad")}
                                        inputProps={{ max: "9999", min: "1" }}
                                        onKeyPress={onlyNumbers}
                                        value={formData.cantidad} onChange={event => handleChange(event)} fullWidth />
                                </Grid>

                                <Grid item md={4} mt="auto" xs={12}>
                                    <Select className={`z-10 ${verifyError("insumo") ? "border-red" : ""}`}
                                        placeholder="* Insumo"
                                        options={insumos}
                                        value={insumo || ''}
                                        onChange={(e) => handleChange(e, "id_insumo", "id_insumo")}
                                        getOptionValue={(option) => `${option.id_insumo}`}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                    />
                                    <small className="font-red">{getError("insumo")}</small>
                                </Grid>

                                <Grid item md={4} mt="auto" xs={12}>
                                    <MDInput 
                                        label="Unidad de medida"
                                        name="unidadMedida"
                                        value={unidadMedida}
                                        inputProps={{ readOnly: true, }}
                                        fullWidth />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <Select className={`z-10 ${verifyError("departamento") ? "border-red" : ""}`}
                                        placeholder="* Departamento"
                                        options={departamentos}
                                        value={departamento || ''}
                                        onChange={(e) => handleChange(e, "id_departamento", "id_departamento")}
                                        getOptionValue={(option) => `${option.id_departamento}`}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                    />
                                    <small className="font-red">{getError("departamento")}</small>
                                </Grid>

                                <Grid item md={4} xs={12} >
                                    &nbsp;
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <MDButton disabled={loading}
                                        className="btnPaymun"
                                        onClick={() => doRequest()} fullWidth >
                                        <Icon fontSize="small">done</Icon>
                                        &nbsp;{editar ? "Actualizar" : "Agregar"}

                                    </MDButton>
                                </Grid>

                                <Grid item md={2} xs={12}>
                                    <MDButton
                                        onClick={() => setShow(true)}
                                        className="btnBackPaymun" fullWidth>
                                        <Icon fontSize="small">arrow_back</Icon>
                                        &nbsp;Regresar
                                    </MDButton>
                                </Grid>
                            </>
                        }
                    </Grid>
                </MDBox>

            </Card>

        </MDBox>
    );
}

DistribucionInsumoDeptoForm.propTypes = {
    editar: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    insumosArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    departamentosArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    getLista: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default DistribucionInsumoDeptoForm;