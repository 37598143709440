/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandTalent from "assets/images/logo_talento.png";
import brandDark from "assets/images/logo-ct-dark.png";

import Pregunta from "layouts/catalogo/Pregunta";
import Fichas from "layouts/servicios/Fichas";

import OssResponsable from "layouts/oss-responsable";
import OssArchivo from "layouts/oss-responsable/archivadas";
import VerDetalles from "layouts/mis-oss/VerDetalles";
import Evidencias from "layouts/mis-oss/Evidencias";
import ResponderPregunta from "layouts/oss-responsable/responderPregunta";

import Ocupacion from "layouts/catalogo/Ocupacion";
import Curso from "layouts/catalogo/Curso";
import Responsables from "layouts/catalogo/Responsables";
import Evaluaciones from "layouts/evaluaciones";
import PuestosBrigadas from "layouts/catalogo/Brigadas";
import Firmante from "layouts/catalogo/Firmante";

import CursanteExterno from "layouts/cursantes-externo/Index";
import ClienteExterno from "layouts/cliente-externo/Index"
import Certificados from "layouts/certificados";

import EvaluacionCursante from "layouts/programacion/evaluaciones/EvaluacionCursante";

import Cursantes from "layouts/cursantes/Index";

import Logout from "auth/Logout";
import SignIn from "layouts/authentication/sign-in";
import AltaCursantes from "layouts/programacion/AltaCursantes";
import CursanteExternoCursos from "layouts/programacion/CursanteExterno";
import Programacion from "layouts/programacion";
import ProgramarContainer from "layouts/programacion/ProgramarContainer";

import ListaCot from "layouts/lista-coti";
import ComentariosAdicionales from "layouts/catalogo/Comentarios";
import CondicionesComerciales from "layouts/catalogo/Condiciones";
import Tables from "layouts/tables";

import ListaOss from "layouts/lista-oss";
import Servicio from "layouts/servicios/Servicio";
import Cliente from "layouts/catalogo/Cliente";
import UsuarioExterno from "layouts/usuarios-externos/Usuario";

import { USER_INSTRUCTOR, USER_ADMIN, USER_LIDER_CAPACITACION, USER_LIDER_SIPA, USER_COTIZACIONES } from "constans";

import DescargarFicha from "layouts/servicios/DescargaFicha";

import RouteGuard from './auth/RouteGuard';
import RouteGuard2 from './auth/RouteGuard2';

import CursanteInvitado from './layouts/cursantes-externo/Index';
import ClienteInvitado from './layouts/catalogo/ClienteExterno'

import UserConfig from "./layouts/user-reset"
import RecoverPassword from "./layouts/user-reset/recover-password"
import ResetPassword from "./layouts/user-reset/reset-password"

import DirectDownload from './DirectDownload';

import './style.css';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  const [searchParams, setSearchParams] = useSearchParams();
  const tokenUserExternal = searchParams.get("tk");

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  /*
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  */

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });



  const userCursanteClienteRoutes = [
    {
      type: "collapse",
      name: "Perfil Usuario",
      key: "cursantes-externo",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/cursantes-externo",
      component: <CursanteExterno />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const userCursanteRoutes = [
    {
      type: "collapse",
      name: "Perfil Usuario",
      key: "cursantes-externo",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/cursantes-externo",
      component: <CursanteExterno />,
    },
    {
      type: "collapse",
      name: "Mis Certificados",
      key: "certificados/lista",
      icon: <Icon fontSize="small">list_alt</Icon>,
      route: "/certificados/lista",
      component: <Certificados />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const userClienteRoutes = [
    {
      type: "collapse",
      name: "Mi perfil de empresa",
      key: "clientes-externo",
      icon: <Icon fontSize="small">list_alt</Icon>,
      route: "/clientes-externo",
      component: <ClienteExterno />,
    },
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/programacion-cursos",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Participantes",
      key: "catalogos-certificado/cursantes",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/catalogos-certificado/cursantes",
      component: <Cursantes />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const InstructorRoutes = [
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/programacion-cursos",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const LiderCapacitacionRoutes = [
    {
      type: "array",
      name: "Cotizaciones",
      key: "cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Nueva Cotización",
          keyArr: "cotizacion/captura-cotizacion",
          iconArr: <Icon fontSize="small">post_add</Icon>,
          routeArr: "/cotizacion/captura-cotizacion",
          componentArr: <Tables />,
        },
        {
          typeArr: "collapse",
          nameArr: "Cotizaciones generadas",
          keyArr: "cotizacion/lista-cotizacion",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/lista-cotizacion",
          componentArr: <ListaCot />,
        },
        {
          typeArr: "collapse",
          nameArr: "Comentarios adicionales",
          keyArr: "cotizacion/comentarios",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/comentarios",
          componentArr: <ComentariosAdicionales />,
        },
        {
          typeArr: "collapse",
          nameArr: "Condiciones comerciales",
          keyArr: "cotizacion/condiciones",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/condiciones",
          componentArr: <CondicionesComerciales />,
        }
      ],
    },
    {
      type: "array",
      name: "Cursos",
      key: "cursos",
      icon: <Icon fontSize="small">model_training</Icon>,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Programación de cursos",
          keyArr: "cursos/programacion-cursos",
          iconArr: <Icon fontSize="small">pending_actions</Icon>,
          routeArr: "/cursos/programacion-cursos",
          componentArr: <Programacion />,
        },
        {
          typeArr: "collapse",
          nameArr: "Cursos registrados",
          keyArr: "cursos/cursos-registrados",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cursos/cursos-registrados",
          componentArr: <Curso />,
        },
        {
          typeArr: "collapse",
          nameArr: "Ocupaciones STPS",
          keyArr: "cursos/ocupaciones-stps",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cursos/ocupaciones-stps",
          componentArr: <Ocupacion />,
        },
        {
          typeArr: "collapse",
          nameArr: "Firmantes",
          keyArr: "cursos/firmantes",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cursos/firmantes",
          componentArr: <Responsables />,
        },
        {
          typeArr: "collapse",
          nameArr: "Evaluaciones",
          keyArr: "cursos/evaluaciones",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cursos/evaluaciones",
          componentArr: <Evaluaciones />,
        },
        {
          typeArr: "collapse",
          nameArr: "Puestos brigadas PC",
          keyArr: "cursos/brigadas-pc",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cursos/puestos-brigadas-pc",
          componentArr: <PuestosBrigadas />,
        },
        {
          typeArr: "collapse",
          nameArr: "Historial Constancias",
          keyArr: "certificados/lista",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/certificados/lista",
          componentArr: <Certificados />,
        },
      ],
    },
    {
      name: "Nueva Cotización",
      key: "cotizacion/captura-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/captura-cotizacion",
      component: <Tables type="cotizacion" />,
    },
    {
      name: "Cotizaciones generadas",
      key: "cotizacion/lista-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/lista-cotizacion",
      component: <ListaCot />,
    },
    {
      name: "Comentarios adicionales",
      key: "cotizacion/comentarios",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/comentarios",
      component: <ComentariosAdicionales />,
    },
    {
      name: "Condiciones comerciales",
      key: "cotizacion/condiciones",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/condiciones",
      component: <CondicionesComerciales />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
    {
      name: "Programación",
      key: "cursos/programacion-cursos",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/cursos/programacion-cursos",
      component: <Programacion />,
    },
    {
      name: "Cursos",
      key: "cursos-registrados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/cursos-registrados",
      component: <Curso />,
    },
    {
      name: "Ocupaciones",
      key: "cursos/ocupaciones-stps",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/ocupaciones-stps",
      component: <Ocupacion />,
    },
    {
      name: "Firmantes",
      key: "firmantes",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/firmantes",
      component: <Firmante />,
    },
    {
      name: "Evaluaciones",
      key: "evaluaciones",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/evaluaciones",
      component: <Evaluaciones />,
    },
    {
      name: "Puestos brigadas PC",
      key: "brigadas",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/puestos-brigadas-pc",
      component: <PuestosBrigadas />,
    },
    {
      name: "Historial Constancias",
      key: "certificados/lista",
      icon: <Icon fontSize="small">list_alt</Icon>,
      route: "/certificados/lista",
      component: <Certificados />,
    },
  ];

  // Lider PC
  const userRoutes = [
    {
      type: "array",
      name: "Cotizaciones",
      key: "cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/captura-cotizacion",
      component: <Tables />,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Nueva Cotización",
          keyArr: "cotizacion/captura-cotizacion",
          iconArr: <Icon fontSize="small">post_add</Icon>,
          routeArr: "/cotizacion/captura-cotizacion",
          componentArr: <Tables />,
        },
        {
          typeArr: "collapse",
          nameArr: "Cotizaciones generadas",
          keyArr: "cotizacion/lista-cotizacion",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/lista-cotizacion",
          componentArr: <ListaCot />,
        },
        {
          typeArr: "collapse",
          nameArr: "Comentarios adicionales",
          keyArr: "cotizacion/comentarios",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/comentarios",
          componentArr: <ComentariosAdicionales />,
        },
        {
          typeArr: "collapse",
          nameArr: "Condiciones comerciales",
          keyArr: "cotizacion/condiciones",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/condiciones",
          componentArr: <CondicionesComerciales />,
        }
      ],
    },
    {
      name: "Nueva Cotización",
      key: "cotizacion/captura-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/captura-cotizacion",
      component: <Tables type="cotizacion" />,
    },
    {
      name: "Cotizaciones generadas",
      key: "cotizacion/lista-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/lista-cotizacion",
      component: <ListaCot />,
    },
    {
      name: "Comentarios adicionales",
      key: "cotizacion/comentarios",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/comentarios",
      component: <ComentariosAdicionales />,
    },
    {
      name: "Condiciones comerciales",
      key: "cotizacion/condiciones",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/condiciones",
      component: <CondicionesComerciales />,
    },
    {
      type: "collapse",
      name: "Mis OSS",
      key: "mis-oss",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/mis-oss",
      component: <OssResponsable />,
    },
    {
      type: "collapse",
      name: "OSS Archivadas",
      key: "oss-archivadas",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/oss-archivadas",
      component: <OssArchivo />,
    },
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos/programacion-cursos",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
    {
      name: "Acciones",
      key: "responder",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/acciones-oss/:idOss/servicio/:idServ",
      component: <ResponderPregunta />,
    },
    {
      name: "Detalles de Oss",
      key: "detalles",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/detalles/:id",
      component: <VerDetalles />,
    },
    {
      name: "Evidencias Subidas",
      key: "detalles",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/evidencias/:id/servicio/:idServ",
      component: <Evidencias />,
    },
  ];

  const LiderSipa = [
    {
      type: "array",
      name: "Cotizaciones",
      key: "cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/captura-cotizacion",
      component: <Tables />,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Nueva Cotización",
          keyArr: "cotizacion/captura-cotizacion",
          iconArr: <Icon fontSize="small">post_add</Icon>,
          routeArr: "/cotizacion/captura-cotizacion",
          componentArr: <Tables />,
        },
        {
          typeArr: "collapse",
          nameArr: "Cotizaciones generadas",
          keyArr: "cotizacion/lista-cotizacion",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/lista-cotizacion",
          componentArr: <ListaCot />,
        },
        {
          typeArr: "collapse",
          nameArr: "Comentarios adicionales",
          keyArr: "cotizacion/comentarios",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/comentarios",
          componentArr: <ComentariosAdicionales />,
        },
        {
          typeArr: "collapse",
          nameArr: "Condiciones comerciales",
          keyArr: "cotizacion/condiciones",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/condiciones",
          componentArr: <CondicionesComerciales />,
        }
      ],
    },
    {
      name: "Nueva Cotización",
      key: "cotizacion/captura-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/captura-cotizacion",
      component: <Tables type="cotizacion" />,
    },
    {
      name: "Cotizaciones generadas",
      key: "cotizacion/lista-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/lista-cotizacion",
      component: <ListaCot />,
    },
    {
      name: "Comentarios adicionales",
      key: "cotizacion/comentarios",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/comentarios",
      component: <ComentariosAdicionales />,
    },
    {
      name: "Condiciones comerciales",
      key: "cotizacion/condiciones",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/condiciones",
      component: <CondicionesComerciales />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const AdminCotizaciones = [
    {
      type: "array",
      name: "Cotizaciones",
      key: "cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/captura-cotizacion",
      component: <Tables />,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Nueva Cotización",
          keyArr: "cotizacion/captura-cotizacion",
          iconArr: <Icon fontSize="small">post_add</Icon>,
          routeArr: "/cotizacion/captura-cotizacion",
          componentArr: <Tables />,
        },
        {
          typeArr: "collapse",
          nameArr: "Cotizaciones generadas",
          keyArr: "cotizacion/lista-cotizacion",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/lista-cotizacion",
          componentArr: <ListaCot />,
        },
        {
          typeArr: "collapse",
          nameArr: "Comentarios adicionales",
          keyArr: "cotizacion/comentarios",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/comentarios",
          componentArr: <ComentariosAdicionales />,
        },
        {
          typeArr: "collapse",
          nameArr: "Condiciones comerciales",
          keyArr: "cotizacion/condiciones",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/cotizacion/condiciones",
          componentArr: <CondicionesComerciales />,
        }
      ],
    },
    {
      name: "Nueva Cotización",
      key: "cotizacion/captura-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/captura-cotizacion",
      component: <Tables type="cotizacion" />,
    },
    {
      name: "Cotizaciones generadas",
      key: "cotizacion/lista-cotizacion",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/lista-cotizacion",
      component: <ListaCot />,
    },
    {
      name: "Comentarios adicionales",
      key: "cotizacion/comentarios",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/comentarios",
      component: <ComentariosAdicionales />,
    },
    {
      name: "Condiciones comerciales",
      key: "cotizacion/condiciones",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cotizacion/condiciones",
      component: <CondicionesComerciales />,
    },
    {
      type: "array",
      name: "OSS",
      key: "oss",
      icon: <Icon fontSize="small">task</Icon>,
      route: "/captura-oss",
      component: <Tables />,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Nueva OSS",
          keyArr: "oss/captura-oss",
          iconArr: <Icon fontSize="small">post_add</Icon>,
          routeArr: "/oss/captura-oss",
          componentArr: <Tables />,
        },
        {
          typeArr: "collapse",
          nameArr: "OSS generadas",
          keyArr: "oss/lista-oss",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/oss/lista-oss",
          componentArr: <ListaOss />,
        },
        {
          typeArr: "collapse",
          nameArr: "Lista de productos y servicios",
          keyArr: "catalogos/servicios",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/catalogos/servicios",
          componentArr: <Servicio />,
        },
        {
          typeArr: "collapse",
          nameArr: "Responsables de OSS",
          keyArr: "catalogos/responsables",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/catalogos/responsables",
          componentArr: <Responsables />,
        },
      ],
    },
    {
      name: "Nueva OSS",
      key: "oss/captura-oss",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/oss/captura-oss",
      component: <Tables type="oss" />,
    },
    {
      name: "OSS generadas",
      key: "oss/lista-oss",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/oss/lista-oss",
      component: <ListaOss />,
    },
    {
      name: "Servicio",
      key: "servicio",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/catalogos/servicios",
      component: <Servicio />,
    },
    {
      name: "Responsables de OSS",
      key: "responsable",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/catalogos/responsables",
      component: <Responsables />,
    },
    {
      type: "array",
      name: "Clientes",
      key: "catalogos",
      icon: <Icon fontSize="small">account_box</Icon>,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Empresas",
          keyArr: "clientes/empresas",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/clientes/empresas",
          componentArr: <Cliente />,
        },
        {
          typeArr: "collapse",
          nameArr: "Independientes",
          keyArr: "clientes/independientes",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "/clientes/independientes",
          componentArr: <Cursantes />,
        },
      ],
    },
    {
      name: "Cliente",
      key: "cliente",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/clientes/empresas",
      component: <Cliente />,
    },
    {
      name: "Participantes",
      key: "participantes/independientes",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/clientes/independientes",
      component: <Cursantes />,
    },
    {
      type: "array",
      name: "Usuarios",
      key: "usuarios",
      icon: <Icon fontSize="small">people</Icon>,
      array: [
        {
          typeArr: "collapse",
          nameArr: "Usuarios externos",
          keyArr: "usuarios/usuarios-externos",
          iconArr: <Icon fontSize="small">list_alt</Icon>,
          routeArr: "usuarios/usuarios-externos",
          componentArr: <UsuarioExterno />,
        },
      ]
    },
    {
      name: "Usuarios externos",
      key: "usuarios/usuarios-externos",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/usuarios/usuarios-externos",
      component: <UsuarioExterno />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    }
  ];

  const getRoutesCustom = () => {
    let routesCust = userRoutes;
    if (tokenUserExternal) {
      routesCust = [];
    }

    if (userData) {
      if (userData.user) {
        if (userData.user.admin === USER_ADMIN) {
          routesCust = routes;
        } else if (userData.user.tipo === 'cursante') {
          routesCust = userCursanteRoutes;
        } else if (userData.user.tipo === 'cliente') {
          routesCust = userClienteRoutes;
        } else if (userData.user.tipo === 'cursante-cliente') {
          routesCust = userCursanteClienteRoutes;
        } else if (userData.user.admin === USER_INSTRUCTOR) {
          routesCust = InstructorRoutes;
        } else if (userData.user.admin === USER_LIDER_CAPACITACION) {
          routesCust = LiderCapacitacionRoutes;
        } else if (userData.user.admin === USER_LIDER_SIPA) {
          routesCust = LiderSipa; // Por ahora aplica para los que solo tienen cotizacion
        } else if (userData.user.admin === USER_COTIZACIONES) {
          routesCust = AdminCotizaciones;
        }
      }
    }
    return routesCust;
  }


  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const content = useMemo(() => <ThemeProvider theme={darkMode ? themeDark : theme}>
    <CssBaseline />
    {layout === "dashboard" && (
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandTalent={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandTalent}
        brandName="Paymun"
        routes={getRoutesCustom()}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
    )}
    {layout === "vr" && <Configurator />}
    <Routes>

      <Route exact path='/' element={<RouteGuard />}>
        <Route exact path='/' element={<RouteGuard2 />} />
        {getRoutes(getRoutesCustom())}
        <Route exact path="descargar/:id" element={<DirectDownload />} />
        <Route path="catalogos/servicios/:id/acciones" element={<Pregunta />} />
        <Route path="catalogos/servicios/:id/fichas" element={<Fichas />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>      
      <Route exact path='/detalle-curso-programado' element={<ProgramarContainer />} />
      <Route exact path='/detalle-curso-programado/:idCursoProgramado' element={<ProgramarContainer />} />
      <Route exact path='descarga-ficha/:id' element={<DescargarFicha />} />
      <Route exact path='/login' element={<SignIn />} />
      <Route exact path='/login-red' element={<RouteGuard2 />} />
      <Route exact path='/cursante-invitado' element={<CursanteInvitado />} />
      <Route exact path='/cursante-invitado-empresa' element={<CursanteInvitado />} />
      <Route exact path='/cliente-invitado' element={<ClienteInvitado />} />
      <Route exact path='/alta-cursantes-curso' element={<CursanteExternoCursos />} />
      <Route exact path='/admin-cursantes-curso' element={<AltaCursantes />} />
      <Route exact path='/evaluacion-curso' element={<EvaluacionCursante />} />
      <Route exact path='/recover-password' element={<RecoverPassword />} />
      <Route exact path='/reset-password' element={<ResetPassword />} />
      
    </Routes>
  </ThemeProvider>, [layout, pathname]);

  return content;
}
